<template>
  <!-- 横向排名占比柱状图 -->
  <div class="rank-chart">
    <MyChart :options="options" />
  </div>
</template>

<script>
import MyChart from "@/components/echart/echart.vue";
// import * as echarts from "echarts";
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    barColor: {
      type: String,
      default: "#67C23A"
    }
  },
  components: {
    MyChart,
  },
  data() {
    return {
      options: {},
    };
  },
  watch: {
    list: {
      immediate: true,
      handler(val) {
        // console.log('val', val);
        if (val && val.length > 0) {
          this.initChart();
        }
        else{
          this.initEmptyChart();
        }

      },
    },
  },
  methods: {
    initChart() {
      let resultData = [...this.list];
      resultData.sort((a, b) => (a.value > b.value ? 1 : -1)); // 升序
      const seriesData = resultData;
      const categoryData = resultData.map((item, index) => {
        const rank = resultData.length - index;
        // const rank = item.type;
        const imgMap = {
          1: { image: require("@/assets/rank1.png") },
          2: { image: require("@/assets/rank2.png") },
          3: { image: require("@/assets/rank3.png") },
        };
        const background = imgMap[rank] ? imgMap[rank] : "#fff";
        return {
          value: rank <= 3 ? "" : `${(rank + "").padStart(2, "0")}.`,
          // value: rank <= 3 ? "" : "",
          textStyle: {
            backgroundColor: background,
            width: 24,
            height: 26,
            align: "center",
            verticalAlign: "middle",
          },
        };
      });
      const maxValue = seriesData[seriesData.length - 1].value;
this.options = {
  legend: { show: false },
  grid: {
    top: 0,
    left: "30%",
    right: "30%",
    bottom: 0,
    containLabel: true,
  },
  tooltip: { show: false },
  xAxis: {
    show: false,
    type: "value",
    max: "dataMax",
    axisLabel: { show: false },
  },
  yAxis: {
    type: "category",
    show: true,
    offset: 50,  // 调整偏移量，使得Y轴标签更紧凑
    axisLine: { show: false },
    axisTick: { show: false },
    data: categoryData,
  },
  series: [
    {
      type: "bar",
      color: ["#F1F4F4"],
      barWidth: 15,  // 减小柱状图宽度，增加紧凑感
      barGap: "-100%",
      silent: true,
      data: seriesData.map((item) => ({
        ...item,
        value: maxValue,
        realValue: item.value,
      })),
      label: {
        show: true,
        color: "#333333",
        fontSize: 20,  // 配置合适的字体大小
        fontWeight: "normal",
        formatter: ({ data }) => `${data.realValue.toFixed(2)}${data.unit || "亩"}`,
        position: "right",
        offset: [10, 0],  // 调整标签的偏移量，确保其与柱子更紧凑
      },
    },
    {
      name: "outlet",
      color: [this.barColor],
      type: "bar",
      barWidth: 12,  // 同样调整
      itemStyle: { color: this.barColor },
      label: {
        show: true,
        color: "#666666",
        fontSize: 14,  // 适当调小字体大小
        formatter: ({ data }) => (data.name.length > 10 ? `${data.name.substr(0, 9)}...` : data.name),
        align: "left",
        position: "left",
        offset: [-140, 0],  // 调整标签的偏移，保证其紧凑
      },
      data: seriesData,
    },
  ],
};


    },
    initEmptyChart(){
      const seriesData =[];
      const categoryData=[];
      const maxValue=0;
      this.options = {
        legend: {
          show: false,
        },
        grid: {
          top: 0,
          left: "30%",
          right: "21%",
          bottom: 0,
          containLabel: true,
        },
        tooltip: {
          show: false,
        },
        xAxis: {
          show: false,
          // offset: 16,
          type: "value",
          max: "dataMax",
          axisLabel: {
            show: false, // 不单独设置 false 虽然不显示，但是还是会占位置
          },
        },
        yAxis: {
          type: "category",
          show: false,
          offset: 200,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          data: categoryData,
        },
        series: [
          {
            type: "bar",
            color: ["#F1F4F4"],
            barWidth: 14,
            barGap: "-100%",
            silent: true,
            data: seriesData.map((item) => {
              return {
                ...item,
                value: maxValue,
                realValue: item.value,
              };
            }),
            label: {
              show: true,
              color: "#333333",
              fontSize: 24,
              fontWeight: "normal",
              formatter: (params) => {
                const { realValue, unit } = params.data;
                return `${realValue}${unit ? unit : "亩"}`;
              },
              position: "right",
              offset: [12, 0],
            },
          },
          {
            name: "outlet",
            color: [this.itemColo],
            type: "bar",
            barWidth: 14,
            label: {
              show: true,
              color: " #666666;",
              fontSize: 16,

              formatter: (params) => {
                const { name, percent } = params.data;
                if (name.toString().length > 10) {
                  return `${name.substr(0, 9)}` + "...";
                } else {
                  return `${name}`;
                }
              },
              align: "left",
              position: "left",
              offset: [-170, 0],
            },
            data: seriesData,
          },
        ],
      };
    }
  },
};
</script>

<style lang="less" scoped>
.rank-chart {
  width: 100%;
  height: 100%;
}
</style>
